import { useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const MainSection = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  minHeight: "300px",
  maxHeight: "300px",
  maxWidth: "300px",
  flexDirection: 'column',
  border: "1px solid rgba(255,255,255,0.5)",
  justifyContent: "center",
  borderRadius: "5px",
  boxShadow:
    "0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)",
  // 5A687D
  overflow: "clip",
  transform: "translate3d(0px, 0px, 0px)",
  "&:hover": {
    boxShadow:
      "0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)",
    transform: "translate3d(0px, -4px, 0px)",
    border: "1px solid rgba(255,255,255,0.8)",
    // 5A687D
  },

  transition: "all 0.25s ease-in-out",
  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
    minHeight: "180px",
    marginTop: "5px",
    marginBotttom: "5px",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
    minHeight: "200px",
    minWidth: "350px",
    maxWidth: "350px",
    marginTop: "5px",
    marginBotttom: "5px",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "column",
    minHeight: "300px",
    minWidth: "350px",
    maxWidth: "350px",
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "column",
    minWidth: "350px",
    maxWidth: "350px"
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontStyle: "normal",
  padding: "20px",
  fontWeight: "600",
  color: "#F2F2F2",
  textShadow: "-5px 5px 5px rgba(0, 0, 0, 0.5)",
  fontSize: "22px",
  // background: "rgba(25,54,82,0.3)",
  transition: "all 0.5s ease-in-out",
  // lineHeight: '20px',
  maxWidth: "500px",
  lineWidth: "500px",
  textWrap: "wrap",
  textAlign: "center",
  zIndex: "1",
  whiteSpace: "pre-line",
  // textWrap: 'wrap',
  wordBreak: "break-word",
  // overflow: 'break-word',
  nowrap: false,
  // textOverflow: "ellipsis",
  // overflowWrap: 'break-word',
  // wordBreak: "break-word",
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
    width: "100%",
  },
}));

const Value = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontStyle: "normal",
  // padding: "20px",
  fontWeight: "600",
  color: "#F2F2F2",
  // textShadow: "-10px 10px 20px rgba(0, 0, 0, 1)",
  fontSize: "16px",
  // background: "rgba(25,54,82,0.3)",
  transition: "all 0.5s ease-in-out",
  // lineHeight: '20px',
  maxWidth: "500px",
  lineWidth: "500px",
  textWrap: "wrap",
  textAlign: "left",
  zIndex: "1",
  whiteSpace: "pre-line",
  // textWrap: 'wrap',
  wordBreak: "break-word",
  // overflow: 'break-word',
  nowrap: false,
  // textOverflow: "ellipsis",
  // overflowWrap: 'break-word',
  // wordBreak: "break-word",
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    width: "100%",
  },
}));

const OpenIcon = styled(LaunchIcon)(({ theme }) => ({
  transition: "all 0.45s ease-in-out",
  // fill: 'white'
}));

interface Props {
  data: any;
}

const CareerCard: React.FC<Props> = ({ data }) => {
  const [isMouseOnTarget, setIsMouseOnTarget] = useState<boolean>(false);
  const navigate = useNavigate();

  const onMouseEnter = async () => {
    setIsMouseOnTarget(true);
  };

  const onMouseLeave = async () => {
    setIsMouseOnTarget(false);
  };
  return (
    <>
      <MainSection
        onClick={() => {
          window.scrollTo({ top: 0, left: 0 });
          navigate(data?.link);
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {/* <MenuImg alt="" src={data?.img} /> */}
        <OpenIcon
          sx={{
            width: "30px",
            height: "30px",
            position: "absolute",
            right: 2,
            top: 2,
            fill: isMouseOnTarget ? "white" : "transparent",
          }}
        />
        <Title>{data?.title}</Title>
        <Box ml={2} mr={2} display='flex' gap='10px' flexDirection='column' minHeight='200px' sx={{border: '0px solid red'}}>
              <Box display='flex' gap="10px"  sx={{minHeight: '30px', border: '0px solid blue', padding: '10px'}}>
                {/*  */}
                 <LocationOnIcon/>
                 <Value>Москва</Value>
              </Box>
              <Box display='flex' gap="10px"  sx={{minHeight: '30px', border: '0px solid blue', padding: '10px'}}>
                <BusinessCenterIcon/>
                <Value>Требуемый опыт работы: 3–6 лет</Value>
              </Box>
              <Box display='flex' gap="10px"  sx={{minHeight: '30px', border: '0px solid blue', padding: '10px'}}>
                <AccessTimeIcon/>
                <Value>Полная занятость, полный день</Value>
              </Box>
        </Box>
      </MainSection>
    </>
  );
};

export default CareerCard;
