import { ComponentsCategory } from "types/components";
import { images } from "./assets";

const photoSections = [
  {
    original: images.G_mpak_1,
    thumbnail: images.G_mpak_1,
  },
  {
    original: images.G_mpak_2,
    thumbnail: images.G_mpak_2,
  },
  {
    original: images.G_mpak_3,
    thumbnail: images.G_mpak_3,
  },
  {
    original: images.G_mpak_4,
    thumbnail: images.G_mpak_4,
  },
  {
    original: images.G_mpak_5,
    thumbnail: images.G_mpak_5,
  },
  {
    original: images.G_mpak_6,
    thumbnail: images.G_mpak_6,
  },
  {
    original: images.G_mpak_7,
    thumbnail: images.G_mpak_7,
  },
  {
    original: images.G_mpak_8,
    thumbnail: images.G_mpak_8,
  },
  {
    original: images.G_mpak_9,
    thumbnail: images.G_mpak_9,
  },
  {
    original: images.G_mpak_10,
    thumbnail: images.G_mpak_10,
  },
  {
    original: images.G_mpak_11,
    thumbnail: images.G_mpak_11,
  },
  {
    original: images.G_mpak_12,
    thumbnail: images.G_mpak_12,
  },
  {
    original: images.G_mpak_15,
    thumbnail: images.G_mpak_15,
  },
  {
    original: images.G_mpak_16,
    thumbnail: images.G_mpak_16,
  },
  {
    original: images.G_mpak_13,
    thumbnail: images.G_mpak_13,
  },
  {
    original: images.G_mpak_14,
    thumbnail: images.G_mpak_14,
  },
  {
    original: images.G_mpak_19,
    thumbnail: images.G_mpak_19,
  },
  {
    original: images.G_mpak_20,
    thumbnail: images.G_mpak_20,
  },
  {
    original: images.G_mpak_17,
    thumbnail: images.G_mpak_17,
  },
  {
    original: images.G_mpak_18,
    thumbnail: images.G_mpak_18,
  },
  {
    original: images.Volks1,
    thumbnail: images.Volks1,
  },
  {
    original: images.Volks2,
    thumbnail: images.Volks2,
  },
  {
    original: images.G_mpu_1,
    thumbnail: images.G_mpu_1,
  },
  {
    original: images.G_mpu_3,
    thumbnail: images.G_mpu_3,
  }
]  

const photoPageSections = [...photoSections];

export {photoPageSections}