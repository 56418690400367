import { FC, useEffect, useState } from 'react';
import { Box, Link, styled, Typography } from '@mui/material';

import { icons } from 'config/constants/assets';
import { Notification } from 'types/notification';

// notification card
const NotificationCardContainer = styled(Box)(() => ({
  cursor: 'pointer',
  borderRadius: '10px',
  border: ' 1px solid #CDC392',
  background: 'rgba(21, 37, 56, 1.00)',
  padding: '10px 9px 10px 9px',
  width: '180px',
  marginRight: '-300px',
  // transform: 'translateX(-100%)',
  transition: 'transform 1s',
}));

const NotificationCardOrigin = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '6px',
  alignItems: 'center',
}));
const Title = styled(Typography)(() => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  color: '#fff',
}));
const ToggleIcon = styled('img')(() => ({
  cursor: 'pointer',
}));

const NotificationCardExpanded = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
const Description = styled(Typography)(() => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  color: '#fff',
}));
const ViewButton = styled(Link)(() => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  color: '#fff',
  textDecoration: 'underline!important',
}));

interface Props {
  data: Notification;
}

const NotificationCard: FC<Props> = ({ data }) => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const { title, description, hasView, txHash } = data;

  useEffect(() => {
    setLoaded(true);
  }, []);

  const onToggle = () => {
    setExpanded(!isExpanded);
  };

  return (
    <NotificationCardContainer onClick={onToggle} sx={{ transform: isLoaded ? 'translateX(-100%)' : '' }}>
      <NotificationCardOrigin>
        <Title>{title}</Title>
        <ToggleIcon
          alt="toggle"
          src=""
          sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
        />
      </NotificationCardOrigin>
      {!!isExpanded && (
        <NotificationCardExpanded>
          <Description>{description}</Description>
          {hasView && (
              <></>
          )}
        </NotificationCardExpanded>
      )}
    </NotificationCardContainer>
  );
};

export default NotificationCard;
