import { FC } from 'react';
import {Box, Grid, styled, Typography } from '@mui/material';
import { MainTitle } from 'components/common/Title';

const Text = styled(Typography)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '400',
    // fontFamily: 'Open Sans',
    color: theme.palette.text.primary,
    fontSize: '18px',
    lineHeight: '20px',
    maxWidth: '100%',
    lineWidth: '500px',
    textWrap: 'wrap',
    textAlign: 'left',
    whiteSpace: "pre-line",
    // textWrap: 'wrap',
    wordBreak: 'break-word',
    // overflow: 'break-word',
    nowrap: false,
    // textOverflow: "ellipsis",
    // overflowWrap: 'break-word',
    // wordBreak: "break-word",
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('sm')]: {
    },
  }));

interface Props {
    children?: React.ReactNode,
    data: any
}

const CharacteristicsTable: FC<Props> = ({ children, data, ...props }) => {
    return (
        <Box mt={0} gap='5px' display='flex' flexDirection='column' sx={{padding: '0px', width: '100%'}}>
            <MainTitle textAlign='left' padding='0' fontSizeXs='20px'>{data?.title}</MainTitle>
            <Box display='flex' gap='15px' flexDirection='column' sx={{border: '0px solid red', padding: '5px'}}>
                <Grid container spacing={0} >
                    <>{data && data
                        .map((row: any, index: number) =>  
                            <>
                                <Grid item xs={6} alignItems='center' justifyContent='center' textAlign='center' sx={{background: (index + 1) % 2 ? '#165F9C' : 'transparent', padding: '4px'}}>
                                    <Box display='flex' alignItems='center' sx={{height: '100%', border: '0px solid green'}}>
                                        <Text>{row?.title}</Text>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sx={{background: (index + 1) % 2 ? '#165F9C' : 'transparent'}}>
                                    <Box display='flex' alignItems='center' sx={{height: '100%', border: '0px solid green'}}>
                                        <Text>{row?.value}</Text>
                                    </Box>
                                </Grid>
                            </>
                    )}</> 
                </Grid>
            </Box>
        </Box>
    );
}

export { CharacteristicsTable };
