
const navigation = [
    {
        original: "company",
        ru: "О Компании",
    },
    {
        original: "products",
        ru: "Продукция",
    },
    {
        original: "podves",
        ru: "Целевые нагрузки",
    },
    {
        original: "gallery",
        ru: "Фтографии",
    },
    {
        original: "aero",
        ru: "Аэростаты",
    },
    {
        original: "mpak",
        ru: "МПАК",
    },
    {
        original: "possibilitys",
        ru: "Возможности",
    },
    {
        original: "contacts",
        ru: "Контакты",
    },
    {
        original: "licenses",
        ru: "Лицензии и сертификаты",
    },
    {
        original: "possibility_1",
        ru: "Разработка электроники",
    },
    {
        original: "possibility_2",
        ru: "Разработка ПО",
    },
    {
        original: "p3",
        ru: "Наши возможности 3",
    },
    {
        original: "p4",
        ru: "Наши возможности 4",
    },
    {
        original: "pgp06dn",
        ru: "ПГП 06-ДН",
    },
    {
        original: "pgp06nd",
        ru: "ПГП 06-НД",
    },
    {
        original: "vertical",
        ru: "Вертикаль",
    },
    {
        original: "mobilePoints",
        ru: "Мобильные пункты",
    },
    {
        original: "mpu",
        ru: "МПУ",
    },
    {
        original: "nabl",
        ru: "Системы наблюдения",
    },
    {
        original: "nabludatel",
        ru: "Наблюдатель",
    },
    {
        original: "nabludatel_N",
        ru: "Наблюдатель-Н",
    },
    {
        original: "vzglyad",
        ru: "Взгляд",
    },
    {
        original: "vzglyadN",
        ru: "Взгляд-Н",
    },
    {
        original: "snegir",
        ru: "Снегирь",
    },
    {
        original: "career",
        ru: "Вакансии",
    },
    {
        original: "career1",
        ru: "Инженер-электронщик / Инженер-регулировщик",
    },
    {
        original: "career2",
        ru: "Ведущий программист встраиваемых систем Linux",
    },
    {
        original: "career3",
        ru: "Слесарь-сборщик",
    },
    {
        original: "career4",
        ru: "Слесарь-сборщик/ Слесарь-монтажник",
    },
    {
        original: "career5",
        ru: "Монтажник РЭА",
    },
    {
        original: "career6",
        ru: "Специалист отдела технического контроля/Контролёр ОТК/менеджер по качеству",
    }
]  

const navigationPage = [...navigation];

export {navigationPage}