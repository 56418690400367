import { ComponentsCategory } from "types/components";
import { images } from "./assets";

const goalsSections = [
  {
    img: images.TestImage7,
    title: "Разработка электроники",
    link: "/possibilitys/possibility_1",
    key: "possibility_1",
    sections: [
      {
        type: ComponentsCategory.MAIN_TITLE,
        key: "AboutTitle",
        data: {
          fontSizeXs: "25px",
          fontSizeMd: "35px",
          fontWeight: "600",
          padding: "20px",
          value: "Разработка электроники",
        },
        childrens: [],
      },
      {
        type: ComponentsCategory.IMAGE_TEXT_BOX,
        data: {
          //  title: "Схемотехника - Топология - Изделие",
          img: images.PCB,
          text: [
            "Разработка электроники является одним из направлений деятельности компании. Вы можете воспользоваться нашими услугами как частично (например, заказать разработку топологии печатной платы) или заказать разработку изделия под ключ, получив в результате готовое изделие в оригинальном корпусе.",
            "",
          ],
        },
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title:
            "Наши специалисты помогут выбрать наиболее оптимальное решение в области разработки",
          text: [
            "При серийном производстве вам не придется ожидать месяцами каких-то редких или непопулярных компонентов, которые могли быть предложены неопытным разработчиком или компанией, которая оптимизирует свою дизайнерскую работу, а не дальнейший жизненный цикл продукта.",
            "Мы подходим к разработке не со стороны удобства разработки, а со стороны производителя, который не понаслышке знает обо всех тонкостях организации серийного производства.",
          ],
        },
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title: "Разработка топологии печатных плат",
          text: [
            "Наши специалисты профессионально разработают топологию печатных плат Вашего изделия по Вашей принципиальной схеме и технологическим требованиям. При этом будет подробно рассмотрена структура будущего изделия, оценены все возможные факторы, влияющие на надежность изделия (мощностные характеристики, частотные характеристики, характеристики EMI). Для используемых в схеме нераспространенных компонентов будут предложены современные и доступные аналоги, которые зачастую могут снизить себестоимость изделия.",
            "При разработке топологии печатной платы будут учтены технологические возможности основных поставщиков печатных плат, чтобы избежать резкого повышения стоимости изготовления плат. Кроме того, будут обеспечены все необходимые условия для качественной сборки изделий на автоматической линии. ",
          ],
        },
      },
    ],
  },
  {
    img: images.TestImage8,
    title: "Разработка ПО",
    link: "/possibilitys/possibility_2",
    key: "possibility_2",
    sections: [
      {
        type: ComponentsCategory.MAIN_TITLE,
        key: "AboutTitle",
        data: {
          fontSizeXs: "25px",
          fontSizeMd: "35px",
          fontWeight: "600",
          padding: "20px",
          value: "Разработка программного обеспечения",
        },
        childrens: [],
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title:
            "Наша компания предлагает услуги по разработке современного программного обеспечения для вашего бизнеса",
          text: [
            "Разработка Linux приложений для систем на базе x86, ARM",
            "Разработка консольных приложений для сервера",
            "Программное обеспечение для встраиваемых систем",
            "Разработка макетов и функциональных прототипов интерфейсов пользователя",
            "Программное обеспечение для встраиваемых систем",
            "Консультирование в области разработки программного обеспечения и его внедрения",
          ],
        },
      },
      {
        type: ComponentsCategory.IMAGE_TEXT_BOX,
        data: {
          // title:
          //   "Разработка программного обеспечения включает полный цикл работ",
          img: images.Chip,
          text: [
            "Разработка программного обеспечения включает полный цикл работ:",
            "◽ Анализ требований и возможностей реализации",
            "◽ Разработка концепции и технических спецификаций",
            "◽ Разработка программных частей и функциональных прототипов",
            "◽ Тестирование и отладка",
            "◽ Внедрение, установка и конфигурация, адаптация",
            "◽ Создание документации для ПО, консультирование и обучение пользователей",
            "◽ Сопровождение и поддержка",
          ],
        },
      },
    ],
  },
  {
    img: images.TestImage6,
    title: "Робототехника",
    link: "/possibilitys/p3",
    key: "p3",
    sections: [
      {
        type: ComponentsCategory.MAIN_TITLE,
        key: "AboutTitle",
        data: {
          fontSizeXs: "25px",
          fontSizeMd: "35px",
          fontWeight: "600",
          padding: "20px",
          value: "Робототехника",
        },
        childrens: [],
      },
      {
        type: ComponentsCategory.IMAGE_TEXT_BOX,
        data: {
          title: "МОБИЛЬНЫЙ РОБОТОТЕХНИЧЕСКИЙ КОМПЛЕКС (МРК)",
          img: images.Robot,
          text: [
            "МРК предназначен для доставки и установки контейнера целевой нагрузки на объект.",
            "В качестве целевой нагрузки возможно использование тепловизора, ретранслятора, устройств радиоэлектронной борьбы, анализатора газов и т.д.",
            "\n\n\n\n*Общий вид Мобильного робота в процессе установки Целевой нагрузки.",
          ],
        },
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title: "Робот",
          text: [
            "Габаритные размеры: (Длина*Ширина), мм                          560*300",
            "Дорожный просвет походный/рабочий, мм                           55/20",
            "Высота при опущенной подвеске, мм                                     160",
            "Высота поверхности для установки ЦН, мм                          190-285",
            "Масса, кг                                                                                    8 +0,3",
          ],
        },
      },
    ],
  },
];

const goalsPageSections = [...goalsSections];

export { goalsPageSections };
