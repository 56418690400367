import React, { useState } from "react";
import {
  Box,
  Button,
  createTheme,
  Grid,
  IconButton,
  Modal,
  styled,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", md: "600px" },
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  background: "linear-gradient(135deg, #265075 0%, #375e85 50%, #1d3d59 100%)",
};

const Text = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: "600",
  color: "black",
  fontSize: "20px",
  lineHeight: "26px",
  maxWidth: "100%",
  lineWidth: "500px",
  textWrap: "wrap",
  textAlign: "left",
  whiteSpace: "pre-line",
  wordBreak: "break-word",
  nowrap: false,
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {},
}));

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: "lightgrey",
          },
          "& label.Mui-focused": {
            color: "white",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "lightgrey",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        },
      },
    },
  },
});

interface Props {
  pageTitle: string;
}

const RequestModal: React.FC<Props> = ({ pageTitle }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [comment, setComment] = useState("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [contactError, setContactError] = useState<boolean>(false);

  // const [error, setError] = useState<string | null>(null);
  // const [success, setSuccess] = useState<string | null>(null);
  const [sendStatus, setSendStatus] = useState<
    "idle" | "sending" | "success" | "error"
  >("idle");

  const OpenModal = () => {
    setSendStatus("idle");
    setOpen(true);
  };

  const handleOpen = () => OpenModal();
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (name.trim() === "") {
      setNameError(true);
      return;
    }
    if (contact.trim() === "") {
      setContactError(true);
      return;
    }

    setNameError(false);
    setContactError(false);

    // setError(null);
    // setSuccess(null);
    setSendStatus("sending");

    const mailSubject = `Заявка со страницы продукта ${pageTitle}`;
    const sendName = `Имя отправителя:\n ${name}`;
    const sendContacts = `\nКонтакты отправителя:\n ${contact}`;
    const sendComment = `\nКомментарий отправителя:\n ${comment}`;

    try {
      const response = await fetch("https://company-page.ru:45500/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mailSubject,
          sendName,
          sendContacts,
          sendComment,
        }),
      });

      // if (!response.ok) {
      //   throw new Error("Server response wasn't OK"); // Check it
      // }

      if (response.ok) {
        setSendStatus("success");
      } else {
        setSendStatus("error");
      }

      // const data = await response.json();
      // setSuccess(data.message);
    } catch (err) {
      if (err instanceof Error) {
        // setError(err.message);
        setSendStatus("error");
      } else {
        // setError("An unknown error occurred");
        setSendStatus("error");
      }
    } finally {
      // setIsSending(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <IconButton
        aria-label="close"
        onClick={handleOpen}
        disableRipple
        sx={{
          color: "lightgrey",
          "&:hover": {
            background: "transparent",
            color: "white",
          },
        }}
      >
        <ForwardToInboxIcon fontSize="large" />
        <Typography
          ml={1}
          variant="h6"
          color="#e0e0e0"
          fontWeight="bold"
          fontFamily="Inter"
          sx={{
            color: "inherit",
          }}
        >
          Отправить запрос
        </Typography>
      </IconButton> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="request-modal-title"
        aria-describedby="request-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size="large"
            sx={{
              position: "absolute",
              right: -8,
              top: -40,
              color: "lightgrey",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="request-modal-title"
            variant="h4"
            component="h2"
            color="#e0e0e0"
            fontWeight="bold"
            textAlign="center"
            fontFamily="Prompt"
          >
            Оставьте заявку
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ваше имя"
                variant="outlined"
                required
                helperText={nameError ? "* Важно заполнить это поле." : ""}
                FormHelperTextProps={{
                  sx: { color: "#e8e8e8" },
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ input: { color: "#e8e8e8" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Номер телефона или email"
                variant="outlined"
                required
                helperText={contactError ? "* Важно заполнить это поле." : ""}
                FormHelperTextProps={{
                  sx: { color: "#e8e8e8" },
                }}
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                sx={{ input: { color: "#e8e8e8" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ваш комментарий"
                variant="outlined"
                multiline
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                inputProps={{ style: { color: "#e8e8e8" } }}
              />
            </Grid>
          </Grid>
          <Box mt={2} width="100%" display="flex" justifyContent="center">
            <Button
              variant="text"
              disabled={sendStatus !== "idle"}
              onClick={handleSubmit}
            >
              <Text
                sx={{
                  fontSize: "50px",
                  color: sendStatus === "error" ? "#d86767" : "#e0e0e0",
                  letterSpacing: 2,
                  "&:hover": {
                    color: "white",
                  },
                }}
              >
                {sendStatus === "idle" && "Отправить заявку"}
                {sendStatus === "sending" && "Отправка..."}
                {sendStatus === "success" && "Заявка отправлена"}
                {sendStatus === "error" && "Ошибка отправки"}
              </Text>
            </Button>
          </Box>
          {/* {error && <div style={{ color: "red" }}>{error}</div>}
          {success && <div style={{ color: "green" }}>{success}</div>} */}
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default RequestModal;
