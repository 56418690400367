import { ComponentsCategory } from "types/components";
import { images } from "./assets";

const aboutSections = [
  {
    type: ComponentsCategory.MAIN_TITLE,
    key: "AboutTitle",
    data: {
      fontSizeXs: "25px",
      fontSizeMd: "35px",
      fontWeight: "600",
      padding: "20px",
      value: "Радиоэлектронные и оптические спецсистемы",
    },
    childrens: [],
  },
  {
    type: ComponentsCategory.IMAGE_TEXT_BOX,
    data: {
      title: "",
      img: images.Test,
      text: [
        "С 1992 года Акционерное общество «Радиоэлектронные и оптические спецсистемы» (АО «РОСС») разрабатывает и производит специальную технику и с 2012 года малообъемные мобильные привязные аэростатные комплексы (МПАК).",
        "",
      ],
    },
  },
  {
    type: ComponentsCategory.TEXT_LIST_BOX,
    data: {
      title: "Результаты деятельности",
      text: [
        "За время деятельности компании, в рамках Гособоронзаказа выполнено более 80 научно-исследовательских и опытно-конструкторских работ.",
        "Техника, разработанная АО «РОСС», по многим показателям превосходит отечественные    и зарубежные аналоги, что подтверждено результатами Государственных испытаний.",
        "Аэростатные комплексы, произведенные АО «РОСС», были задействованы силовыми ведомствами России при решении задач по обеспечению безопасности подготовки и проведения массовых мероприятий: \n◽ Универсиад 2013 года в г. Казань и 2019 года в г. Красноярк. \n◽ Олимпийских и Параолимпийских зимних игр 2014 года в г. Сочи. \n◽ Чемпионата мира по футболу 2018 года.",
        "Аэростатный комплекс видеонаблюдения показал высокую эффективность в решении боевых и обеспечивающих задач группировки войск Министерства Обороны Российской Федерации (Минобороны России) в Сирийской Арабской Республике и в настоящее время.",
        "МПАК применялся для обеспечения безопасности первых лиц России при проведении значимых общественно-политических мероприятий и крупных выставок: \n◽ Международный авиакосмический салон (МАКС – с 2017 года) в г. Жуковском. \n◽  Международный военно-технический форум «Армия» - с 2015 года",
      ],
    },
  },
  {
    type: ComponentsCategory.INFO_BOX,
    data: {
      title: "КРИТЕРИИ УСПЕХА",
      boxes: [
        {
          title: "КАЧЕСТВО",
          values: [
            "Высоким профессионализмом сотрудников",
            "Разработанные изделия выпускаются серийно по документации литеры «О₁»",
            "Система менеджмента качества сертифицирована по ГОСТ Р ИСО 9001-2015 и дополнительным требованиям ГОСТ РВ 0015-002-2012",
          ],
        },
        {
          title: "НАДЕЖНОСТЬ",
          values: [
            "Стойкость к сложным метеоусловиям",
            "Защита от ошибок пилотирования",
            "При создании изделий АО «РОСС» использует новейшие мировые достижения науки и техники.",
          ],
        },
        {
          title: "КОНТРОЛЬ ЗА ИЗГОТВЛИВАЕМОЙ ПРОДУКЦИЕЙ",
          values: [
            "ОТК предприятия",
            "Научно-исследовательские подразделения",
            "Военные представительства силовых ведомств России",
          ],
        },
      ],
    },
  },
  // {
  //   type: ComponentsCategory.IMAGE_TEXT_BOX,
  //   data: {
  //     title: "Test Title 4",
  //     img: images.Test,
  //     text: [
  //       "Аэростатный комплекс видеонаблюдения показал высокую эффективность в решении боевых и обеспечивающих задач группировки войск Министерства Обороны Российской Федерации (Минобороны России) в Сирийской Арабской Республике.",
  //       "",
  //     ],
  //   },
  // },
  {
    type: ComponentsCategory.IMAGE_BUTTON,
    data: [
      {
        img: images.DocumentsImage,
        title: "ЛИЦЕНЗИИ И СЕРТИФИКАТЫ",
        link: "/company/licenses",
        key: "lic",
      },
      {
        img: images.CompassImage,
        title: "КОНТАКТЫ",
        link: "/company/contacts",
        key: "con",
      },
    ],
  },
];

const aboutPageSections = [...aboutSections];

export { aboutPageSections };
