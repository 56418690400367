import { ComponentsCategory } from "types/components";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ScaleIcon from "@mui/icons-material/Scale";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import Battery5BarIcon from "@mui/icons-material/Battery5Bar";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import PreviewIcon from "@mui/icons-material/Preview";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import NightlightIcon from "@mui/icons-material/Nightlight";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import HubIcon from "@mui/icons-material/Hub";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import HeightIcon from "@mui/icons-material/Height";
import { images } from "./assets";

const productsSections = [
  {
    title: "Целевые нагрузки",
    img: images.Snegir,
    link: "/products/podves",
    key: "podves",
    items: [
      {
        title: "ПГП 06-ДН",
        link: "/products/podves/pgp06dn",
        key: "pgp06dn",
        shortDescriprion:
          "Оптико-электронный поворотно-гиростабилизированный подвес (День-ночь)",
        img: images.PGP2,
        images: [
          {
            original: images.PGP2,
            thumbnail: images.PGP2,
          },
          {
            original: images.PGPfoto,
            thumbnail: images.PGPfoto,
          },
        ],
        keyNotes: [
          {
            logo: PreviewIcon,
            title: "Идентификация гос.номера 1 км",
          },
          {
            logo: DirectionsWalkIcon,
            title: "Обнаружение человека 5 км",
          },
          {
            logo: DirectionsCarIcon,
            title: "Распознавание автомобиля 10 км",
          },
        ],
        fullDescription: [
          {
            title:
              "Предназначен для работы преимущественно в дневных условиях.",
          },
          {
            title: "Оптический канал",
            values: [
              "Идентификация автомобильного госномера на расстоянии до 1 км",
              "Обнаружение объекта типа «человек» до 5 км",
            ],
          },
          {
            title: "Тепловизионный канал",
            values: [
              "Обнаружение объекта типа «человек» до 1 км",
              "Обнаружение автомобиля до 4 км",
            ],
          },
        ],
        characteristics: [
          {
            title: "---",
            value: "---",
          },
          {
            title: "---",
            value: "---",
          },
        ],
      },
      // {
      //   title: "ПГП 06-НД",
      //   link: "/products/podves/pgp06nd",
      //   key: "pgp06nd",
      //   shortDescriprion:
      //     "Оптико-электронный поворотно-гиростабилизированный подвес (Ночь-день)",
      //   img: images.PGP1,
      //   images: [
      //     {
      //       original: images.PGP1,
      //       thumbnail: images.PGP1,
      //     },
      //     {
      //       original: images.PGPndview,
      //       thumbnail: images.PGPndview,
      //     },
      //   ],
      //   keyNotes: [
      //     {
      //       logo: PreviewIcon,
      //       title: "Идентификация гос.номера 0.5 км",
      //     },
      //     {
      //       logo: DirectionsWalkIcon,
      //       title: "Обнаружение человека 4 км",
      //     },
      //     {
      //       logo: DirectionsCarIcon,
      //       title: "Распознавание автомобиля 8 км",
      //     },
      //   ],
      //   fullDescription: [
      //     {
      //       title:
      //         "Предназначен для работы преимущественно в ночных условиях (оснащен охлаждаемым тепловизором). ",
      //     },
      //     {
      //       title: "Оптический канал",
      //       values: [
      //         "Идентификация автомобильного госномера на расстоянии до 0,5 км",
      //         "Обнаружение объекта типа «человек» до 3 км",
      //         "Распознавание типа автомобиля до 8 км",
      //       ],
      //     },
      //     {
      //       title: "Тепловизионный канал",
      //       values: [
      //         "Обнаружение объекта типа «человек» до 4 км",
      //         "Обнаружение автомобиля до 8 км",
      //       ],
      //     },
      //   ],
      //   characteristics: [
      //     {
      //       title: "---",
      //       value: "---",
      //     },
      //     {
      //       title: "---",
      //       value: "---",
      //     },
      //   ],
      // },
      // {
      //   title: "Вертикаль",
      //   link: "/products/podves/vertical",
      //   key: "vertical",
      //   shortDescriprion: "Гиростабилизированное оптико-электронное средство",
      //   img: images.CameraVertical,
      //   images: [
      //     {
      //       original: images.CameraVertical,
      //       thumbnail: images.CameraVertical,
      //     },
      //   ],
      //   keyNotes: [
      //     {
      //       logo: PreviewIcon,
      //       title: "Идентификация гос.номера 1 км",
      //     },
      //     {
      //       logo: DirectionsWalkIcon,
      //       title: "Обнаружение человека 5 км",
      //     },
      //     {
      //       logo: DirectionsCarIcon,
      //       title: "Обнаружение автомобиля 10 км",
      //     },
      //   ],
      //   fullDescription: [
      //     {
      //       title:
      //         "Гиростабилизированная	оптико-электронная	средство	(ОЭС) видимого диапазона.",
      //       values: [
      //         "ОЭС предназначена для установки на беспилотный летательный аппарат (БЛА) мультироторного типа и осуществления визуального контроля местности с БЛА.",
      //         "",
      //       ],
      //     },
      //   ],
      //   characteristics: [
      //     {
      //       title: "Угол поворота оптико-электронного устройства (ОЭУ):",
      //       value:
      //         "• По курсу - 360\n• По крену - от -47 до +47\n• По тангажу от -128 до 61",
      //     },
      //     {
      //       title: "Диапазон напряжения питания, В",
      //       value: "24-52",
      //     },
      //     {
      //       title: "Скорость поворота по курсу и тангажу, °/с",
      //       value: "От 0 до 60",
      //     },
      //     {
      //       title:
      //         "Точность активной трехосной стабилизации ОЭУ (среднеквадратическое отклонение ошибки), мкрад. (угл. сек.)",
      //       value: "Не более - 75(15,5)",
      //     },
      //     {
      //       title: "Минимальное угловое поле зрения (по вертикали) ОЭУ, °",
      //       value: "Не более: 0.6",
      //     },
      //     {
      //       title:
      //         "Диапазон изменения фокусного расстояния объектива ТВ камеры, крат. (зум)",
      //       value: "24",
      //     },
      //     {
      //       title: "Разрешение ТВ камеры, пикс.",
      //       value: "1920х1080",
      //     },
      //     {
      //       title: "Масса изделия, кг",
      //       value: "4,5",
      //     },
      //     {
      //       title:
      //         "Возможность захвата, автоматического удержания и автосопровождения выбранного оператором объекта (стационарного и подвижного) в пределах заданных углов вращения",
      //       value: "ДА",
      //     },
      //     {
      //       title: "Программная стабилизази",
      //       value: "ДА",
      //     },
      //     {
      //       title: "Запись видео на SD карту",
      //       value: "ДА",
      //     },
      //   ],
      // },
      // {
      //   title: "Снегирь",
      //   link: "/products/podves/snegir",
      //   key: "snegir",
      //   shortDescriprion:
      //     "Система дальнего видеонаблюдения «Снегирь», «Снегирь-Н»",
      //   img: images.Snegir,
      //   images: [
      //     {
      //       original: images.Snegir,
      //       thumbnail: images.Snegir,
      //     },
      //     {
      //       original: images.G_snegir_1,
      //       thumbnail: images.G_snegir_1,
      //     },
      //     {
      //       original: images.G_snegirN_1,
      //       thumbnail: images.G_snegirN_1,
      //     },
      //   ],
      //   keyNotes: [
      //     {
      //       logo: CrisisAlertIcon,
      //       title: "Определение координат объекта",
      //     },
      //     {
      //       logo: DirectionsWalkIcon,
      //       title: "Обнаружение человека 1,5 км",
      //     },
      //     {
      //       logo: DirectionsCarIcon,
      //       title: "Обнаружение автомобиля 4 км",
      //     },
      //   ],
      //   fullDescription: [
      //     {
      //       title:
      //         "Изделие предназначено для использования на подвижных платформах транспортных средств и беспилотных летательных аппаратах.",
      //     },
      //     {
      //       title: "Назначение",
      //       values: [
      //         "Средство дальнего видеонаблюдения в дневное время в видимом диапазоне («Снегирь»);",
      //         "Средство дальнего видеонаблюдения в дневное и ночное время в инфракрасном диапазоне («Снегирь-Н»)",
      //       ],
      //     },
      //     {
      //       title: "Возможности",
      //       values: [
      //         "Обнаружение человека до 1,5 км",
      //         "Обнаружение автомобиля до 4 км",
      //         "Програмная стабилизация;",
      //         "Автоматическое сопровождение объекта",
      //         "Определение геодезических координат объекта.",
      //       ],
      //     },
      //   ],
      //   characteristics: [
      //     {
      //       title: "Поворотный гиростабилизированный подвес:",
      //       value: "",
      //     },
      //     {
      //       title: "Углы поворота (качания)",
      //       value:
      //         "азимут, град.: 360 (неограниченное вращение)\nугол места, град.: от -135 до +45",
      //     },
      //     {
      //       title: "Точность стабилизации, угл./сек СКО",
      //       value: "60",
      //     },
      //     {
      //       title: "Масса, кг",
      //       value: "1,2",
      //     },
      //     {
      //       title: "Габаритные размеры (ДхШхВ), мм",
      //       value: "125х125х180",
      //     },
      //     {
      //       title: "Диапазон рабочих температур, град.",
      //       value: "От -40 до +40",
      //     },
      //     {
      //       title: "Канал наблюдения «Снегирь»:",
      //       value: "",
      //     },
      //     {
      //       title: "Разрешение видеосигнала, пикс.",
      //       value: "1280х720",
      //     },
      //     {
      //       title: "Фокусное расстояние (зум, крат), мм",
      //       value: "4,6-152 (33)",
      //     },
      //     {
      //       title: "Угол обзора, град.",
      //       value:
      //         "зум 1х: 60,1(Г) х 35,3(В) (общий план)\nзум 33х: 2,1(Г) х 1,2(В) (максимальный зум)",
      //     },
      //     {
      //       title: "Тепловизионный канал «Снегирь-Н»",
      //       value: "",
      //     },
      //     {
      //       title: "Разрешение видеосигнала, пикс.",
      //       value: "1024x768",
      //     },
      //     {
      //       title: "Фокусное расстояние (зум, крат), мм",
      //       value: "15-75(5)",
      //     },
      //     {
      //       title: "Угол обзора, град.",
      //       value:
      //         "зум 1х: 51,1(Г) х 39,4(В) (общий план)\nзум 5х: 10,9(Г) х 8,2(В) (максимальный зум)",
      //     },
      //   ],
      // },
    ],
  },
  {
    title: "Системы наблюдения",
    img: images.Nabl,
    link: "/products/nabl",
    key: "nabl",
    items: [
      {
        title: "Наблюдатель",
        img: images.Nabl,
        link: "/products/nabl/nabludatel",
        key: "nabludatel",
        shortDescriprion:
          "Система дальнего видеонаблюдения «Наблюдатель», «Наблюдатель-ДР»",
        images: [
          {
            original: images.Nabl,
            thumbnail: images.Nabl,
          },
          {
            original: images.G_nabl_1,
            thumbnail: images.G_nabl_1,
          },
          {
            original: images.G_nabl_2,
            thumbnail: images.G_nabl_2,
          },
        ],
        keyNotes: [
          {
            logo: PreviewIcon,
            title: "Идентификация гос.номера 1.5 км",
          },
          {
            logo: DirectionsWalkIcon,
            title: "Обнаружение человека 7 км",
          },
          {
            logo: DirectionsCarIcon,
            title: "Обнаружение автомобиля 17 км",
          },
        ],
        fullDescription: [
          {
            title: "Назначение",
            values: [
              "Индивидуальное средство дальнего видеонаблюдения в дневное время в видимом диапазоне",
              "Построение комплексов дальнего видеонаблюдения в дневное время в видимом диапазоне",
              "Интеграция в существующие системы видеонаблюдения",
            ],
          },
          {
            title: "Возможности",
            values: [
              "Обзорный канал: контроль окружающей обстановки",
              "Канал детализации:",
              "• Распознавание гос. номера автомобиля до 1,5 км",
              "• Обнаружение человека до 7 км",
              "• Обнаружение автомобиля до 17 км",
              "Контроль назначенной зоны в автоматическом режиме",
              "Автоматическое сопровождение объекта",
              "Лазерный дальномер (модификация «Наблюдатель-ДР»): измерение расстояния до объекта наблюдения от 200 м до 12000 м с точностью 5 м.",
            ],
          },
        ],
        characteristics: [
          {
            title: "Опорно-поворотное устройство:",
            value: "",
          },
          {
            title: "Углы поворота (качания), град.",
            value: "азимут от -170 до +170\nугол места от -40 до +85",
          },
          {
            title: "Диапазон рабочих температур, град.",
            value: "От -30 до +40",
          },
          {
            title: "Канал детализации:",
            value: "",
          },
          {
            title: "Разрешение видеосигнала, пикс.",
            value: "1920х1080",
          },
          {
            title: "Фокусное расстояние (зум, крат), мм",
            value: "20 - 750 (37)",
          },
          {
            title: "Углы обзора, град.",
            value:
              "зум 1х - 14(Г) х 7,9(В) (общий план)\nзум 37х - 0,38(Г) х 0,21(В) (максимальный зум)",
          },
          {
            title: "Обзорный канал:",
            value: "",
          },
          {
            title: "Разрешение видеосигнала, пикс.",
            value: "1920х1080",
          },
          {
            title: "Фокусное расстояние, мм",
            value: "3,7",
          },
          {
            title: "Углы обзора, град.",
            value: "70,0 (Г) х 43,0 (В)",
          },
          {
            title: "Лазерный дальномер «Наблюдатель-ДР»:",
            value: "",
          },
          {
            title: "Точность измерения, м",
            value: "5",
          },
          {
            title: "Измерение расстояния, м",
            value: "От 200 до 12000",
          },
        ],
      },
      {
        title: "Наблюдатель-Н",
        img: images.NablN,
        link: "/products/nabl/nabludatel_N",
        key: "nabludatel_N",
        shortDescriprion:
          "Система дальнего видеонаблюдения «Наблюдатель-Н», «Наблюдатель-Н-ДР»",
        images: [
          {
            original: images.NablN,
            thumbnail: images.NablN,
          },
          {
            original: images.G_nablN_1,
            thumbnail: images.G_nablN_1,
          },
          {
            original: images.G_nablN_2,
            thumbnail: images.G_nablN_2,
          },
        ],
        keyNotes: [
          {
            logo: DirectionsWalkIcon,
            title: "Обнаружение человека 4 км",
          },
          {
            logo: DirectionsCarIcon,
            title: "Обнаружение автомобиля 8 км",
          },
          {
            logo: NightlightIcon,
            title: "Тепловизор с зумом 18,5х",
          },
        ],
        fullDescription: [
          {
            title: "Назначение",
            values: [
              "Индивидуальное средство дальнего видеонаблюдения в дневное и ночное время в видимом диапазоне",
              "Построение комплексов дальнего видеонаблюдения в дневное и ночное время в видимом диапазоне",
              "Интеграция в существующие системы видеонаблюдения",
            ],
          },
          {
            title: "Возможности",
            values: [
              "Обзорный канал: контроль окружающей обстановки",
              "Канал детализации на базе охлаждаемого тепловизора:",
              "• Обнаружение человека до 4 км",
              "• Обнаружение автомобиля до 8 км",
              "Контроль назначенной зоны в автоматическом режиме",
              "Автоматическое сопровождение объекта",
              "Лазерный дальномер (модификация «Наблюдатель-Н-ДР»): измерение расстояния до объекта наблюдения от 200 м до 12000 м с точностью 5 м.",
            ],
          },
        ],
        characteristics: [
          {
            title: "Опорно-поворотное устройство:",
            value: "",
          },
          {
            title: "Углы поворота (качания), град.",
            value: "азимут от -170 до +170\nугол места от -40 до +85",
          },
          {
            title: "Диапазон рабочих температур, град.",
            value: "От -30 до +40",
          },
          {
            title: "Канал наблюдения:",
            value: "",
          },
          {
            title: "Разрешение видеосигнала, пикс.",
            value: "640х480",
          },
          {
            title: "Фокусное расстояние (зум, крат), мм",
            value: "15-280 (18,5)",
          },
          {
            title: "Углы обзора, град.",
            value:
              "зум 1х - 35,5(Г) х 28,7(В) (общий план)\nзум 18,5х - 2,0(Г) х 1,6(В) (максимальный зум)",
          },
          {
            title: "Обзорный канал:",
            value: "",
          },
          {
            title: "Разрешение видеосигнала, пикс.",
            value: "1920х1080",
          },
          {
            title: "Фокусное расстояние, мм",
            value: "3,7",
          },
          {
            title: "Углы обзора, град.",
            value: "70,0 (Г) х 43,0 (В)",
          },
          {
            title: "Лазерный дальномер «Наблюдатель-Н-ДР»:",
            value: "",
          },
          {
            title: "Точность измерения, м",
            value: "5",
          },
          {
            title: "Измерение расстояния, м",
            value: "От 200 до 12000",
          },
        ],
      },
      {
        title: "Взгляд",
        img: images.Vzglyad,
        link: "/products/nabl/vzglyad",
        key: "vzglyad",
        shortDescriprion: "Система видеонаблюдения",
        images: [
          {
            original: images.Vzglyad,
            thumbnail: images.Vzglyad,
          },
          {
            original: images.G_vzglyad_1,
            thumbnail: images.G_vzglyad_1,
          },
        ],
        keyNotes: [
          {
            logo: PreviewIcon,
            title: "Идентификация гос.номера 750 м",
          },
          {
            logo: DirectionsWalkIcon,
            title: "Обнаружение человека 3,5 км",
          },
          {
            logo: DirectionsCarIcon,
            title: "Обнаружение автомобиля 8 км",
          },
        ],
        fullDescription: [
          {
            title: "Назначение",
            values: [
              "Индивидуальное средство видеонаблюдения в дневное время в видимом диапазоне",
              "Построение комплексов дальнего видеонаблюдения в дневное время в видимом диапазоне",
              "Интеграция в существующие системы видеонаблюдения",
            ],
          },
          {
            title: "Возможности",
            values: [
              "Обзорный канал: контроль окружающей обстановки",
              "Канал детализации:",
              "• Распознавание гос. номера автомобиля до 750 м",
              "• Обнаружение человека до 3,5 км",
              "• Обнаружение автомобиля до 8 км",
              "удаленное управление комплексом на расстоянии до 3 км по радиоканалу",
            ],
          },
        ],
        characteristics: [
          {
            title: "Опорно-поворотное устройство:",
            value: "",
          },
          {
            title: "Скорость поворота осей, град./сек.",
            value: "ступенчатое изменение (с шагом 18) от 0 до 180",
          },
          {
            title: "Углы поворота (качания), град.",
            value: "азимут: без ограничения\nугол места: от -90 до +90",
          },
          {
            title: "Точность поворота, угл. сек (СКО)",
            value: "0,3",
          },
          {
            title: "Диапазон рабочих температур, град.",
            value: "От -30 до +40",
          },
          {
            title: "Канал наблюдения:",
            value: "",
          },
          {
            title: "Разрешение видеосигнала, пикс.",
            value: "1920х1080",
          },
          {
            title: "Фокусное расстояние (зум, крат), мм",
            value: "6 - 540 (90)",
          },
          {
            title: "Углы обзора, град.",
            value:
              "зум 1х - 59(Г) х 37(В) (общий план)\nзум 90х - 0,8(Г) х 0,5(В) (максимальный зум)",
          },
          {
            title: "Масса оптического модуля, кг",
            value: "2",
          },
          {
            title: "Габаритные размеры оптического модуля, Д х Ш х В, мм",
            value: "235 х 90 х 95",
          },
          {
            title: "Потребление, Вт",
            value: "6",
          },
        ],
      },
      {
        title: "Взгляд-Н",
        img: images.VzglyadN,
        link: "/products/nabl/vzglyadN",
        key: "vzglyadN",
        shortDescriprion: "Система видеонаблюдения",
        images: [
          {
            original: images.VzglyadN,
            thumbnail: images.VzglyadN,
          },
          {
            original: images.G_vzglyadN_1,
            thumbnail: images.G_vzglyadN_1,
          },
        ],
        keyNotes: [
          {
            logo: DirectionsWalkIcon,
            title: "Обнаружение человека 1 км",
          },
          {
            logo: DirectionsCarIcon,
            title: "Обнаружение автомобиля 2,5 км",
          },
          {
            logo: ScaleIcon,
            title: "Компактный размер и малый вес",
          },
        ],
        fullDescription: [
          {
            title:
              "Отличительной особенностью комплексов «Взгляд-Н» являются высокие показатели рабочих дальностей при компактных размерах и малом весе.",
            values: [""],
          },
          {
            title: "Назначение",
            values: [
              "Индивидуальное средство видеонаблюдения в дневное и ночное время в видимом диапазоне",
            ],
          },
          {
            title: "Возможности",
            values: [
              "Обнаружение человека до 1 км",
              "Обнаружение автомобиля до 2,5 км",
            ],
          },
        ],
        characteristics: [
          {
            title: "Тепловизионный модуль:",
            value: "",
          },
          {
            title: "Спектральный диапазон, мкм",
            value: "LWIR (8-14)",
          },
          {
            title: "Тип видеосигнала",
            value: "PAL",
          },
          {
            title: "Разрешение видиосигнала, пикс.",
            value: "640х480",
          },
          {
            title: "Частота кадров, к/сек.",
            value: "25",
          },
          {
            title: "Чувствительность, мК",
            value: "40",
          },
          {
            title: "Масса, кг",
            value: "0,5",
          },
          {
            title: "Габаритные размеры, Д х Ш х В, мм",
            value: "100х60х67",
          },
          {
            title: "Потребление, Вт",
            value: "5",
          },
          {
            title: "Объектив №1",
            value: "",
          },
          {
            title: "Фокусное расстояние, мм",
            value: "20",
          },
          {
            title: "Углы обзора, град.",
            value: "21,7(Г) х 16,3(В)",
          },
          {
            title: "Обнаружение человека, м",
            value: "400",
          },
          {
            title: "Масса, кг",
            value: "0,1",
          },
          {
            title: "Объектив №2",
            value: "",
          },
          {
            title: "Фокусное расстояние, мм",
            value: "54",
          },
          {
            title: "Углы обзора, град.",
            value: "8,1(Г) х 6,1(В)",
          },
          {
            title: "Обнаружение человека, м",
            value: "1000",
          },
          {
            title: "Масса, кг",
            value: "0,25",
          },
        ],
      },
    ],
  },
  {
    title: "Мобильные пункты",
    img: images.Volksnoback,
    link: "/products/mobilePoints",
    key: "mobilePoints",
    items: [
      {
        title: "МПУ",
        img: images.Volksnoback,
        link: "/products/mobilePoints/mpu",
        key: "mpu",
        shortDescriprion: "Мобильный пункт управления",
        images: [
          {
            original: images.Volks1,
            thumbnail: images.Volks1,
          },
          {
            original: images.Volks2,
            thumbnail: images.Volks2,
          },
          {
            original: images.G_mpu_1,
            thumbnail: images.G_mpu_1,
          },
          {
            original: images.G_mpu_2,
            thumbnail: images.G_mpu_2,
          },
          {
            original: images.G_mpu_3,
            thumbnail: images.G_mpu_3,
          },
          {
            original: images.G_mpu_4,
            thumbnail: images.G_mpu_4,
          },
        ],
        keyNotes: [
          {
            logo: ControlCameraIcon,
            title: "Видеонаблюдение,\nдата-центр",
          },
          {
            logo: SettingsIcon,
            title: "Управление роботами",
          },
          {
            logo: SettingsIcon,
            title: "Пункт радиоконтроля",
          },
          {
            logo: HubIcon,
            title: "Ситуационный центр",
          },
        ],
        fullDescription: [
          {
            title:
              "Комплекс размещён на 2-х автомобилях Volkswagen (Transporter Kasten и Multivan).",
            values: [
              "МПУ обеспечивает одновременный прием и запись аудио и видео информации объемом не менее 10 Тбайт в формате Full HD 1920 х 1080 в реальном времени от 8-ми внешних источников.",
              "",
            ],
          },
          {
            title: "Основные сферы применения",
            values: [
              "Комплекс видеонаблюдения",
              "Комплекс радиоконтроля",
              "Комплекс дата-центра",
              "Ситуационный центр",
              "Управление роботами и беспилотными летательными аппаратами",
            ],
          },
        ],
        characteristics: [
          {
            title: "Состав командного автомобиля",
            value:
              "• рабочее место оператора\n• оборудование охранного телевидения автомобиля\n• оборудование жизнеобеспечения персонала\n• электрооборудование\n• оборудование вычислительной сети\n• приёмо-передающее оборудование\n• дополнительное оборудование в автомобиле",
          },
          {
            title: "Состав технического автомобиля",
            value:
              "• рабочее место оператора\n• оборудование охранного телевидения автомобиля\n• оборудование жизнеобеспечения персонала\n• электрооборудование\n• оборудование вычислительной сети\n• приёмо-передающее оборудование\n• дополнительное оборудование в автомобиле",
          },
        ],
      },
    ],
  },
  {
    title: "Аэростаты",
    img: images.Aero,
    link: "/products/aero",
    key: "aero",
    items: [
      {
        title: "МПАК",
        key: "mpak",
        shortDescriprion: "Мобильный привязной аэростатный комплекс",
        link: "/products/aero/mpak",
        img: images.G_mpak_1,
        images: [
          {
            original: images.G_mpak_1,
            thumbnail: images.G_mpak_1,
          },
          {
            original: images.G_mpak_2,
            thumbnail: images.G_mpak_2,
          },
          {
            original: images.G_mpak_3,
            thumbnail: images.G_mpak_3,
          },
          {
            original: images.G_mpak_4,
            thumbnail: images.G_mpak_4,
          },
          {
            original: images.G_mpak_5,
            thumbnail: images.G_mpak_5,
          },
          {
            original: images.G_mpak_6,
            thumbnail: images.G_mpak_6,
          },
          {
            original: images.G_mpak_7,
            thumbnail: images.G_mpak_7,
          },
          {
            original: images.G_mpak_8,
            thumbnail: images.G_mpak_8,
          },
          {
            original: images.G_mpak_9,
            thumbnail: images.G_mpak_9,
          },
          {
            original: images.G_mpak_10,
            thumbnail: images.G_mpak_10,
          },
          {
            original: images.G_mpak_11,
            thumbnail: images.G_mpak_11,
          },
          {
            original: images.G_mpak_12,
            thumbnail: images.G_mpak_12,
          },
          {
            original: images.G_mpak_15,
            thumbnail: images.G_mpak_15,
          },
          {
            original: images.G_mpak_16,
            thumbnail: images.G_mpak_16,
          },
          {
            original: images.G_mpak_13,
            thumbnail: images.G_mpak_13,
          },
          {
            original: images.G_mpak_14,
            thumbnail: images.G_mpak_14,
          },
          {
            original: images.G_mpak_19,
            thumbnail: images.G_mpak_19,
          },
          {
            original: images.G_mpak_20,
            thumbnail: images.G_mpak_20,
          },
          {
            original: images.G_mpak_17,
            thumbnail: images.G_mpak_17,
          },
          {
            original: images.G_mpak_18,
            thumbnail: images.G_mpak_18,
          }
        ],
        keyNotes: [
          {
            logo: HeightIcon,
            title: "Рабочая высота до 1000 м.",
          },
          {
            logo: ScaleIcon,
            title: "Масса полезной нагрузки до 16 кг.",
          },
          {
            logo: DeviceThermostatIcon,
            title: "Диапазон температур: -30 +40",
          },
        ],
        fullDescription: [
          {
            title:
              "МПАК – высокотехнологичное изделие, применяемое как в военных, так и в гражданских целях.",
            // values: [
            //   "МПАК – высокотехнологичное изделие, применяемое как в военных, так и в гражданских целях.",
            //   "",
            // ],
          },
          {
            title: "Возможности применения МПАК",
            values: [
              "Оптический контроль наземной, надводной и воздушной обстановки",
              "Обнаружение и выдача целеуказаний по объектам наземного, морского и воздушного базирования",
              "Ретрансляция связи",
              "Радиоэлектронное противодействие",
              "Радиолокационный дозор",
            ],
          },
          {
            title: "Преимущества МПАК",
            values: [
              "◇ Мобильность",
              "Возможность размещения МПАК на различных автомобильных шасси (два автомобиля повышенной проходимости,микроавтобус с прицепом, грузовик и т.д.)",
              "◇ Автономность",
              "Круглосуточная автономная работа (без внешнего электропитания, дополнительного подвоза несущего газа и топлива) на необорудованной площадке в течение 6 суток (в состав МПАК входит электрогенератор мощностью 10 кВт)",
              "◇ Универсальность",
              "Размещение различного оборудования (в т. ч. заказчика) на универсальной подвесной системе:",
              "• cистемы дальнего видеонаблюдения",
              "• cистемы ретрансляции радиосигналов",
              "• cистемы поиска и определения координат мобильных телефонов",
              "• cистемы радиоразведки",
              "• cистемы радиоэлектронной борьбы",
              "◇ Совместимость с оборудованием заказчика",
              "◇ Ремонтопригодность",
              "◇ Простота эксплуатации",
              "◇ Независимость от точки старта",
              "◇ Всепогодность",
            ],
          },
          {
            title: "Состав комплекса",
            values: [
              "Аэростат объемом 50 м3 с подвесной системой",
              "Два полноприводных автомобиля повышенной проходимости",
              "Аэростатная лебедка АЭМЛ-2",
              "Электрогенератор",
              "Газозаправочное оборудование с 10 газовыми баллонами",
              "Пункт управления (ПУ)",
              "Комплекс может поставляться с различными оптико-электронными поворотно-гиростабилизированными подвесами (ПГП06-ДН, ПГП06-НД)",
            ],
          },
        ],
        characteristics: [
          {
            title: "Расчет персонала для обслуживания комплекса:",
          },
          {
            title: "- при развёртывании в рабочее положение и при свёртывании",
            value: "5-6 человек",
          },
          {
            title: "- при эксплуатации",
            value: "2-3 человека",
          },
          {
            title: "Максимальная масса полезной нагрузки (ПН)",
            value: "16 кг.",
          },
          {
            title: "Диапазон рабочих температур",
            value: "от -30 до +40",
          },
          {
            title: "Скорость ветра:",
          },
          {
            title: "- максимально допустимая",
            value: "15 м/с",
          },
          {
            title: "- при газонаполненнии",
            value: "6 м/с",
          },
          {
            title: "Максимальная рабочая высота:",
          },
          {
            title: "- 5 кг полезной нагрузки с рамой",
            value: "1000 м",
          },
          {
            title: "- 16 кг полезной нагрузки с рамой",
            value: "300 м",
          },
          {
            title: "- Объем",
            value: "50 м куб (При увеличении массы полезной нагрузки объем оболочки может быть изменен)",
          },
        ],
      },
    ],
  },
];

const productsPageSections = [...productsSections];

export { productsPageSections };
