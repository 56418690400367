import React, { FC, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout';
import CompanyPage from 'pages/Company';
import ProductsPage from 'pages/Products';
import ProductItemPage from 'pages/ProductItem';
import ProductItems from 'pages/ProductItems';
import GalleryPage from 'pages/Gallery';
import PossibilitysPage from 'pages/Possibilitys';
import PossibilityItemPage from 'pages/PossibilityItem';
import ContactsPage from 'pages/Contacts';
import LicensesPage from 'pages/Licenses';
import CareerPage from 'pages/Career';
import CareerItemPage from 'components/Career/CareerItemPage';

const OverviewPage = React.lazy(() => import('./pages/Overview'));

const Router: FC = () => {
  const [counter2, setCounter2] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
        setCounter2(counter2 + 1);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ counter2]);

  const routes = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Navigate replace to="/overview" /> },
        { path: '/overview', element: <OverviewPage /> },
        { path: '/company', element: <CompanyPage /> },
        { path: '/products', element: <ProductsPage /> },
        { path: '/products/:items', element: <ProductItems /> },
        { path: '/products/:items/:item', element: <ProductItemPage /> },
        { path: '/possibilitys', element: <PossibilitysPage /> },
        { path: '/possibilitys/:item', element: <PossibilityItemPage /> },
        { path: '/gallery', element: <GalleryPage /> },
        { path: '/company/contacts', element: <ContactsPage /> },
        { path: '/company/licenses', element: <LicensesPage /> },
        { path: '/career', element: <CareerPage /> },
        { path: '/career/:item', element: <CareerItemPage /> }
      ],
    },

    { path: '*', element: <Navigate replace to="/" /> },
  ]);

  return routes;
};

export default Router;
