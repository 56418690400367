import { ComponentsCategory,ContactsFieldType } from "types/components";
import { images } from "./assets";

const careerSections = [
  {
    img: images.TestImage7,
    title: "Инженер-электронщик / Инженер-регулировщик",
    link: "/career/career1",
    key: "career1",
    contacts: [
      {
        type: ComponentsCategory.CONTACTS_BOX,
        data: {
          img: "",
          fields: [
            {
              title: "Телефон:",
              value: [
                {
                  visibleValue: "+7(495)600-84-96",
                  linkValue: "tel:+74956008496",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "",
              value: [
                {
                  visibleValue: "+7(495)675-60-29",
                  linkValue: "tel:+74956756029",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "Email: ",
              value: [
                {
                  visibleValue: "ross@ross-jsc.ru",
                  linkValue: "mailto:ross@ross-jsc.ru",
                },
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 2,
            },
            {
              title: "Адрес: ",
              value: [
                {
                  visibleValue: "Ул. 2-я Машиностроения д. 25,стр. 5",
                  linkValue: "Ул. 2-я Машиностроения д. 25, стр. 5",
                },
              ],
              type: ContactsFieldType.TITLE_AND_VALUE,
              offset: 2,
            },
          ]
        }
      }
    ],
    sections: [
      {
        type: ComponentsCategory.MAIN_TITLE,
        key: "AboutTitle",
        data: {
          fontSizeXs: "25px",
          fontSizeMd: "35px",
          fontWeight: "600",
          padding: "20px",
          value: "Инженер-электронщик / Инженер-регулировщик",
        },
        childrens: [],
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title:
            "Обязанности",
          text: [
            "Регулировка радиоэлектронных изделий в соответствии с технической документацией.",
            "Измерения и контроль параметров изделий на компонентном и блочном уровне.",
            "Предъявление изделий на приёмку ОТК и представителю Заказчика."
          ],
        },
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title: "Что мы предлагаем:",
          text: [
            "Оформление по ТК.",
            "Компенсация больничного.",
            "Ежегодная индексация заработной платы.",
            "Гибкий подход к рабочему графику.",
            "Обучение и повышение квалификации за счет компании.",
            "ДМС.",
            "Компенсация фитнеса.",
            "Удобное расположение офиса.",
            "Дружный коллектив.",
            "Участие в корпоративных праздничных и культурных мероприятиях."
          ],
        },
      },
    ],
  },
  {
    img: images.TestImage7,
    title: "Ведущий программист встраиваемых систем Linux",
    link: "/career/career2",
    key: "career2",
    contacts: [
      {
        type: ComponentsCategory.CONTACTS_BOX,
        data: {
          img: "",
          fields: [
            {
              title: "Телефон:",
              value: [
                {
                  visibleValue: "+7(495)600-84-96",
                  linkValue: "tel:+74956008496",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "",
              value: [
                {
                  visibleValue: "+7(495)675-60-29",
                  linkValue: "tel:+74956756029",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "Email: ",
              value: [
                {
                  visibleValue: "ross@ross-jsc.ru",
                  linkValue: "mailto:ross@ross-jsc.ru",
                },
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 2,
            },
            {
              title: "Адрес: ",
              value: [
                {
                  visibleValue: "Ул. 2-я Машиностроения д. 25,стр. 5",
                  linkValue: "Ул. 2-я Машиностроения д. 25, стр. 5",
                },
              ],
              type: ContactsFieldType.TITLE_AND_VALUE,
              offset: 2,
            },
          ]
        }
      }
    ],
    sections: [
      {
        type: ComponentsCategory.MAIN_TITLE,
        key: "AboutTitle",
        data: {
          fontSizeXs: "25px",
          fontSizeMd: "35px",
          fontWeight: "600",
          padding: "20px",
          value: "Ведущий программист встраиваемых систем Linux",
        },
        childrens: [],
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title:
            "Обязанности",
          text: [
            "Проектирование и реализация систем и компонентов с использованием C, C++, QT и других языков (Python/Bash) в программном окружении Linux. ",
            "Написание кода как для высокоуровневых приложений, так и для драйверов устройств на уровне регистров. Использование процессоров и аппаратных периферийных устройств. ",
            "Сопровождение и улучшение ранее написанного программного обеспечения.",
            "Создание технической документации по разрабатываемому ПО."
          ],
        },
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title: "Что мы предлагаем:",
          text: [
            "Оформление по ТК.",
            "Компенсация больничного.",
            "Ежегодная индексация заработной платы.",
            "Гибкий подход к рабочему графику.",
            "Обучение и повышение квалификации за счет компании.",
            "ДМС.",
            "Компенсация фитнеса.",
            "Удобное расположение офиса.",
            "Дружный коллектив.",
            "Участие в корпоративных праздничных и культурных мероприятиях."
          ],
        },
      },
    ],
  },
  {
    img: images.TestImage7,
    title: "Слесарь-сборщик",
    link: "/career/career3",
    key: "career3",
    contacts: [
      {
        type: ComponentsCategory.CONTACTS_BOX,
        data: {
          img: "",
          fields: [
            {
              title: "Телефон:",
              value: [
                {
                  visibleValue: "+7(495)600-84-96",
                  linkValue: "tel:+74956008496",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "",
              value: [
                {
                  visibleValue: "+7(495)675-60-29",
                  linkValue: "tel:+74956756029",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "Email: ",
              value: [
                {
                  visibleValue: "ross@ross-jsc.ru",
                  linkValue: "mailto:ross@ross-jsc.ru",
                },
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 2,
            },
            {
              title: "Адрес: ",
              value: [
                {
                  visibleValue: "Ул. 2-я Машиностроения д. 25,стр. 5",
                  linkValue: "Ул. 2-я Машиностроения д. 25, стр. 5",
                },
              ],
              type: ContactsFieldType.TITLE_AND_VALUE,
              offset: 2,
            },
          ]
        }
      }
    ],
    sections: [
      {
        type: ComponentsCategory.MAIN_TITLE,
        key: "AboutTitle",
        data: {
          fontSizeXs: "25px",
          fontSizeMd: "35px",
          fontWeight: "600",
          padding: "20px",
          value: "Слесарь-сборщик",
        },
        childrens: [],
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title:
            "Обязанности",
          text: [
            "Изготовление листовых деталей из металлов толщиной до 1,5 мм.",
            "Работа с клеями (ультрафиолетовым, эпоксидными, цианокрилатный, резиновый).",
            "Установка развальцовочного крепежа.",
            "Сборка изделий с использованием крепежа с резьбой от М1 до М4.",
            "Нарезка резьбы от М1 до М4.",
            "Доработка деталей при сборке напильником, сверлом и т.п.",
            "Работа на сверлильном станке.",
            "Работа с “дремелем” (абразивный инструмент).",
            "Ручная работа с деревом.",
            "Разборка и сборка мелкой электронной аппаратуры."
          ],
        },
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title: "Что мы предлагаем:",
          text: [
            "Оформление по ТК.",
            "Компенсация больничного.",
            "Ежегодная индексация заработной платы.",
            "Гибкий подход к рабочему графику.",
            "Обучение и повышение квалификации за счет компании.",
            "ДМС.",
            "Компенсация фитнеса.",
            "Удобное расположение офиса.",
            "Дружный коллектив.",
            "Участие в корпоративных праздничных и культурных мероприятиях."
          ],
        },
      },
    ],
  },
  {
    img: images.TestImage7,
    title: "Слесарь-сборщик/ Слесарь-монтажник",
    link: "/career/career4",
    key: "career4",
    contacts: [
      {
        type: ComponentsCategory.CONTACTS_BOX,
        data: {
          img: "",
          fields: [
            {
              title: "Телефон:",
              value: [
                {
                  visibleValue: "+7(495)600-84-96",
                  linkValue: "tel:+74956008496",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "",
              value: [
                {
                  visibleValue: "+7(495)675-60-29",
                  linkValue: "tel:+74956756029",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "Email: ",
              value: [
                {
                  visibleValue: "ross@ross-jsc.ru",
                  linkValue: "mailto:ross@ross-jsc.ru",
                },
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 2,
            },
            {
              title: "Адрес: ",
              value: [
                {
                  visibleValue: "Ул. 2-я Машиностроения д. 25,стр. 5",
                  linkValue: "Ул. 2-я Машиностроения д. 25, стр. 5",
                },
              ],
              type: ContactsFieldType.TITLE_AND_VALUE,
              offset: 2,
            },
          ]
        }
      }
    ],
    sections: [
      {
        type: ComponentsCategory.MAIN_TITLE,
        key: "AboutTitle",
        data: {
          fontSizeXs: "25px",
          fontSizeMd: "35px",
          fontWeight: "600",
          padding: "20px",
          value: "Слесарь-сборщик/ Слесарь-монтажник",
        },
        childrens: [],
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title:
            "Обязанности",
          text: [
            "Выполнение работы по сборке и монтажу электронных и оптических устройств.",
            "Сборка металлоизделий в производственном цеху.",
            "Соблюдение производственного календаря.",
            "Участие в проверке изделий (в пределах рабочего места).",
          ],
        },
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title: "Что мы предлагаем:",
          text: [
            "Оформление по ТК.",
            "Компенсация больничного.",
            "Ежегодная индексация заработной платы.",
            "Гибкий подход к рабочему графику.",
            "Обучение и повышение квалификации за счет компании.",
            "ДМС.",
            "Компенсация фитнеса.",
            "Удобное расположение офиса.",
            "Дружный коллектив.",
            "Участие в корпоративных праздничных и культурных мероприятиях."
          ],
        },
      },
    ],
  },
  {
    img: images.TestImage7,
    title: "Монтажник РЭА",
    link: "/career/career5",
    key: "career5",
    contacts: [
      {
        type: ComponentsCategory.CONTACTS_BOX,
        data: {
          img: "",
          fields: [
            {
              title: "Телефон:",
              value: [
                {
                  visibleValue: "+7(495)600-84-96",
                  linkValue: "tel:+74956008496",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "",
              value: [
                {
                  visibleValue: "+7(495)675-60-29",
                  linkValue: "tel:+74956756029",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "Email: ",
              value: [
                {
                  visibleValue: "ross@ross-jsc.ru",
                  linkValue: "mailto:ross@ross-jsc.ru",
                },
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 2,
            },
            {
              title: "Адрес: ",
              value: [
                {
                  visibleValue: "Ул. 2-я Машиностроения д. 25,стр. 5",
                  linkValue: "Ул. 2-я Машиностроения д. 25, стр. 5",
                },
              ],
              type: ContactsFieldType.TITLE_AND_VALUE,
              offset: 2,
            },
          ]
        }
      }
    ],
    sections: [
      {
        type: ComponentsCategory.MAIN_TITLE,
        key: "AboutTitle",
        data: {
          fontSizeXs: "25px",
          fontSizeMd: "35px",
          fontWeight: "600",
          padding: "20px",
          value: "Монтажник РЭА",
        },
        childrens: [],
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title:
            "Обязанности",
          text: [
            "Монтаж прототипов и мелких серий электронных модулей.",
            "Монтаж сложных компонентов, включая BGA микросхемы.",
            "Изготовление кабелей, проведение монтажа модулей и блоков.",
          ],
        },
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title: "Что мы предлагаем:",
          text: [
            "Оформление по ТК.",
            "Компенсация больничного.",
            "Ежегодная индексация заработной платы.",
            "Гибкий подход к рабочему графику.",
            "Обучение и повышение квалификации за счет компании.",
            "ДМС.",
            "Компенсация фитнеса.",
            "Удобное расположение офиса.",
            "Дружный коллектив.",
            "Участие в корпоративных праздничных и культурных мероприятиях."
          ],
        },
      },
    ],
  },
  {
    img: images.TestImage7,
    title: "Специалист отдела технического контроля/Контролёр ОТК/менеджер по качеству",
    link: "/career/career6",
    key: "career6",
    contacts: [
      {
        type: ComponentsCategory.CONTACTS_BOX,
        data: {
          img: "",
          fields: [
            {
              title: "Телефон:",
              value: [
                {
                  visibleValue: "+7(495)600-84-96",
                  linkValue: "tel:+74956008496",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "",
              value: [
                {
                  visibleValue: "+7(495)675-60-29",
                  linkValue: "tel:+74956756029",
                }
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 0,
            },
            {
              title: "Email: ",
              value: [
                {
                  visibleValue: "ross@ross-jsc.ru",
                  linkValue: "mailto:ross@ross-jsc.ru",
                },
              ],
              type: ContactsFieldType.TITLE_AND_LINK,
              offset: 2,
            },
            {
              title: "Адрес: ",
              value: [
                {
                  visibleValue: "Ул. 2-я Машиностроения д. 25,стр. 5",
                  linkValue: "Ул. 2-я Машиностроения д. 25, стр. 5",
                },
              ],
              type: ContactsFieldType.TITLE_AND_VALUE,
              offset: 2,
            },
          ]
        }
      }
    ],
    sections: [
      {
        type: ComponentsCategory.MAIN_TITLE,
        key: "AboutTitle",
        data: {
          fontSizeXs: "25px",
          fontSizeMd: "35px",
          fontWeight: "600",
          padding: "20px",
          value: "Специалист отдела технического контроля/Контролёр ОТК/\nменеджер по качеству",
        },
        childrens: [],
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title:
            "Обязанности",
          text: [
            "Осуществляет входной контроль поступающих на производство изделий на соответствие требованиям конструкторской документации, техническим условиям и т.д.",
            "Обеспечивает технический контроль за качеством работ по установленному технологическому процессу.",
          ],
        },
      },
      {
        type: ComponentsCategory.TEXT_LIST_BOX,
        data: {
          title: "Что мы предлагаем:",
          text: [
            "Оформление по ТК.",
            "Компенсация больничного.",
            "Ежегодная индексация заработной платы.",
            "Гибкий подход к рабочему графику.",
            "Обучение и повышение квалификации за счет компании.",
            "ДМС.",
            "Компенсация фитнеса.",
            "Удобное расположение офиса.",
            "Дружный коллектив.",
            "Участие в корпоративных праздничных и культурных мероприятиях."
          ],
        },
      },
    ],
  },
];

const careerPageSections = [...careerSections];

export { careerPageSections };
