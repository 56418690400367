import React, { useState } from 'react';
import { Box, Dialog, Grid, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    // borderRadius: '12px',
    maxWidth: '900px',
    width: '100%',
    background: 'rgba(25,54,82,0.98)',
    padding: '30px 30px',
    border: '1px solid grey',
  },
}));

const ProductBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '10px',
    maxHeight: '200px',
    position: 'relative',
    minHeight: '200px',
    minWidth: '200px',
    background: '#24496b',
    border: '1px solid rgba(25,54,82,0.5)', 
    boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
   // 5A687D
    // overflow: 'clip',
    transform: 'translate3d(0px, 0px, 0px)',
    "&:hover": {
      boxShadow:
      '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
      transform: 'translate3d(0px, -4px, 0px)',
      border: '1px solid rgba(25,54,82,0.8)', 
      // 5A687D
    },
    transition: 'transform 0.25s ease-in-out',
}));
const CloseBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px',
    borderRadius: '25px',
    position: 'absolute',
    border: '1px solid rgba(25,54,82,0.5)', 
    boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
   // 5A687D
    overflow: 'clip',
    // transform: 'translate3d(0px, 0px, 0px)',
    "&:hover": {
      transform: 'translate3d(0px, 0px, 0px)',
      border: '1px solid grey', 
      // 5A687D
    },
    transition: 'all 0.25s ease-in-out',
}));    

const ModalTitle = styled(Typography)(({ theme }) => ({
//   fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '19px',
  lineHeight: '25px',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  textWrap: 'wrap',
  textAlign: 'center',
  whiteSpace: "pre-line",
  // textWrap: 'wrap',
  wordBreak: 'break-word',
  // overflow: 'break-word',
  nowrap: false,
}));

const OpenIcon = styled(LaunchIcon)(({ theme }) => ({
  transition: 'all 0.45s ease-in-out',
  // fill: 'white'
}));

interface Props {
    data: any
}

const ProductItemCard: React.FC<Props> = ({ data }) => {
    const [isMouseOnTarget, setIsMouseOnTarget] = useState(false);
    const navigate = useNavigate();

    const onMouseEnter = async () => {
        setIsMouseOnTarget(true)
    };

    const onMouseLeave = async () => {
        setIsMouseOnTarget(false)
    };

  return (
    <ProductBox sx={{zIndex: isMouseOnTarget ? 1 : 0}} onClick={() => {
        window.scrollTo({top: 0, left: 0})
        navigate(data?.link)}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {/* { isMouseOnTarget && */}
          <OpenIcon sx={{width: '30px', height: '30px', position: 'absolute', right: 2, top: 2, fill: isMouseOnTarget ? 'white' : 'transparent'}}/>
      {/* } */}
      <img style={{objectFit: 'cover', height: '100%', maxHeight: '150px'}} src={data?.img} alt=''/>
      <ModalTitle sx={{fontWeight: '500'}}>{data?.title}</ModalTitle>
      {isMouseOnTarget && 
        <Box display='flex' mt={2} justifyContent='center' alignItems='center' sx={{position: 'absolute', bottom: -80, minHeight: '80px', width: '100%', borderBottomRightRadius: '5px',borderBottomLeftRadius: '5px', background: '#1B3751', zIndex: 2, padding: '10px', opacity:"0.8"}}>
            <ModalTitle sx={{fontSize: '16px'}}>{data?.shortDescriprion}</ModalTitle>
        </Box>
      }
    </ProductBox>
  );
};

export default ProductItemCard;
