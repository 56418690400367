import { FC, useState } from "react";
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "state/hooks";
import { icons, images } from "config/constants/assets";
import { ExtraMenu } from "components/ExtraMenu";
import SearchIcon from "@mui/icons-material/Search";
import SearchModal from "components/SearchModal/SearchModal";

const AppHeader = styled(Box)(({ theme }) => ({
  height: "75px",
  position: "sticky",
  top: "0px",
  zIndex: 150,
  // background: window.scrollY > 0 ? '#16191E' : "transparent",
  // background: '#122638', // 0E1C28
  background: "#1a3752", // 0E1C28
  padding: "0px 24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // borderBottom: '1px solid rgba(255, 255, 255, 0.7)',

  [theme.breakpoints.up("xs")]: {
    height: "53px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "75px",
  },
}));

const HeaderContent = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  width: "100%",
  maxWidth: "1150px",
  margin: "auto",
}));

// logo section
const LogoSection = styled(Box)(() => ({
  cursor: "pointer",
  "&:hover .hover-highlight": {
    color: "#fff",
    borderBottom: "1px solid white",
  },
}));

const MenuImg = styled("img")(() => ({
  minWidth: "22px",
  maxWidth: "25px",
}));

// action section
const AccountSection = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  textAlign: "center",
  alignItems: "center",
  "&:hover .hover-highlight": {
    color: "#fff",
    borderBottom: "1px solid white",
  },
}));

const Label = styled(Typography)(() => ({
  // fontFamily: 'Open Sans',
  fontStyle: "normal",
  fontWeight: "700",
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: "18px",
  color: "rgba(255, 255, 255, 0.7)",
  // "&:hover": {
  //   color: "#fff",
  //   borderBottom: '1px solid white'
  // },
  transition: "all 0.25s ease-in-out",
}));

const Header: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [extraMenuOpened, setExtraMenuOpened] = useState<boolean>(false);
  const [searchOpened, setSearchOpened] = useState<boolean>(false);
  const navigate = useNavigate();
  const onCloseExtraMenu = () => {
    setExtraMenuOpened(false);
  };
  const [searchIsOpen, setOpen] = useState(false);
  const onCloseSearch = () => {
    setSearchOpened(false);
    setOpen(false);
  };

  return (
    <AppHeader>
      {extraMenuOpened && <ExtraMenu onClose={onCloseExtraMenu} />}
      <SearchModal open={searchIsOpen} onClose={onCloseSearch} />
      <HeaderContent>
        <LogoSection display="flex" flexDirection="row" alignItems="center">
          <MenuImg
            sx={{ mr: 2 }}
            alt="logo"
            onClick={() => setExtraMenuOpened(true)}
            src={icons.hamburger}
          />
          {!isMobile && (
            <Label
              className="hover-highlight"
              onClick={() => setExtraMenuOpened(true)}
            >
              МЕНЮ
            </Label>
          )}
        </LogoSection>
        <LogoSection
          onClick={() => {
            navigate("/overview");
            window.scrollTo({ top: 0, left: 0 });
          }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <img
            style={{ minWidth: "150px", maxWidth: "150px" }}
            alt="logo"
            src={images.NewLogo}
          />
        </LogoSection>

        <Box alignItems="center" display="flex" gap="24px">
          <AccountSection>
            {!isMobile && (
              <Label onClick={() => setOpen(true)} className="hover-highlight">
                ПОИСК
              </Label>
            )}
            <SearchIcon
              onClick={() => setOpen(true)}
              sx={{ marginLeft: "5px", fill: "rgba(255, 255, 255, 0.7)" }}
            />
          </AccountSection>
        </Box>
      </HeaderContent>
    </AppHeader>
  );
};

export default Header;
