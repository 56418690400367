import { ComponentsCategory } from "types/components";
import { images } from "./assets";

const licensesSections = [
  {
    type: ComponentsCategory.WHIDE_IMAGE,
    data: {
      img: images.DocumentsImage,
    },
  },
  {
    type: ComponentsCategory.TEXT_LIST_BOX,
    data: {
      // title: "ЛИЦЕНЗИИ И СЕРТИФИКАТЫ",
      text: ["Лицензия УФСБ России по городу Москве и Московской области на проведение работ, связанных с использованием сведений, составляющих государственную тайну:  Регистрационный номер 38135\n Срок действия: 16.01.2023-25.02.2026\n Серия ГТ №0134093 \n", 
          "Лицензия на осуществление разработки, производства, реализации и приобретения в целях продажи специальных технических средств, предназначенных для негласного получения информации:  Регистрационный номер 100\n Срок действия: 03.03.2021 – бессрочно \n ",
          "Лицензия на осуществление разработки, производства, испытания и ремонта авиационной техники:  Регистрационный номер 14943-АТ\n Срок действия: 14.01.2022 – бессрочно\n",
          "Сертификат Соответствия СМК ВР 21.1.18827-2024 от  2 сентября 2024.  Начало сертификационного цикла - с 01.09.2018 "
      ]
    }
},
];

const licensesPageSections = [...licensesSections];

export { licensesPageSections };
