import { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { extraMenuItems } from 'config/constants/header';
import { Link} from 'react-scroll';
import CloseIcon from '@mui/icons-material/Close';

const ExtraMenuContainer = styled(Box)(({ theme }) => ({}));

const ExtraMenuContent = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '0px',
  left: '0px',
  zIndex: 150,
  background: '#254c6e',
  padding: '35px 35px',
  height: '100vh',
  width: '400px',
  transition: '2.5s all ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',

  [theme.breakpoints.up('xs')]: {},
  [theme.breakpoints.up('sm')]: {},
}));

const OverlayBg = styled(Box)(() => ({
  position: 'fixed',
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  zIndex: 10,
  background: '#000000',
  opacity: 0.3,
}));

// menu section
const MenuSection = styled(Box)(({ theme }) => ({}));

const MenuContent = styled(Box)(() => ({
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

// menu item
const MenuItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'flex-start',
  // '&:hover': {
  //   opacity: '0.8',
  // },
}));

const MenuItemName = styled(Typography)(() => ({
  // fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  // cursor: 'pointer',
  fontSize: '18px',
  lineHeight: 'normal',
  color: 'rgba(255, 255, 255, 1.0)',
  // "&:hover": {
  //   color: "#fff",
  //   borderBottom: '1px solid white'
  // },
}));

// menu sub item
const MenuSubItemContent = styled(Box)(() => ({
  // marginLeft: '28px',
  // width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

const LinkStyle = styled(Link)(({theme}) => ({
  lineHeight: 0.8,
  cursor: "pointer",
  fontSize: "25px",
  fontWeight: 500,
  color: theme.palette.common.gradient,
  borderTop: '0px solid white',
  borderBottom  : '0px solid white',
  borderRight: '0px solid white',
  borderLeft: '0px solid white',
  minWidth: '120px',
  maxHeight: '35px',
  padding: "8px",
  textAlign: 'left',
  // background: theme.palette.common.gradient,
  "&:hover": {
    color: "white",
    borderColor: '#5F5D9C'
  },
  "&.active": {
    color: "white",
    borderColor: '#5F5D9C'
  },
}));

const Label = styled(Typography)(({theme}) => ({
  // fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  // fontSize: '25px',
  lineHeight: '40px',
  color: 'rgba(255, 255, 255, 0.7)',
  "&:hover": {
    color: "#fff",
    borderBottom: '1px solid white'
  },
  transition: 'all 0.25s ease-in-out',
  [theme.breakpoints.up('xs')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '25px',
  },
}));

interface ExtraMenuProps {
  onClose: () => void;
}

const ExtraMenu: FC<ExtraMenuProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // close opended extra menu
  const onCollapse = () => {
    onClose();
  };

  const onClickSubMenu = (menu: any) => {
    if (menu.isExternal) {
      window.open(menu.link, '_blank');
    } else {
      window.scrollTo({left: 0, top: 0})
      navigate(menu.link);
      onCollapse();
    }
  };
  
  return (
    <ExtraMenuContainer>
      <OverlayBg onClick={onCollapse} />
      <ExtraMenuContent>
        <MenuSection>
          <MenuContent>
            {extraMenuItems[1] &&
              extraMenuItems[1].map((row) => (
                <Box key={row.key}>
                  <MenuItem textAlign='left' mb={2} onClick={onCollapse} sx={{ opacity: row.link ? '1' : '1', border: '0px solid red' }}>
                    <CloseIcon/>
                    <MenuItemName ml={2}>{row.name}</MenuItemName>
                  </MenuItem>
                  {row.hasSubItems && row.subItems && row.subItems.length > 0 && (
                    <MenuSubItemContent>
                      {row.subItems &&
                        row.subItems.map((subItem: any) => (
                          <LinkStyle
                          onClick={() => onClickSubMenu(subItem)}
                          key={subItem.key}
                          to={subItem.url}
                          spyThrottle={10}
                          offset={-100}
                          smooth={!!true}
                          duration={500}
                          activeClass="active"
                        >
                          <Label>
                            {subItem.name}
                          </Label>
                      </LinkStyle>
                        ))}
                    </MenuSubItemContent>
                  )}
                </Box>
              ))}
          </MenuContent>
        </MenuSection>
      </ExtraMenuContent>
    </ExtraMenuContainer>
  );
};

export { ExtraMenu };
